// This regex is given by Backend Team
export const PLAYER_REGEX = /^[a-z][a-z0-9]{3,10}[a-z0-9]$/
export const PLAYER_REGEX_FOR_SEARCH = /^[a-z][a-z0-9]{2,10}[a-z0-9]$/

export const PLAYER_BANK_ACCOUNT_NAME = /^[\p{L}\p{Mark}.\s]+$/u

// This const is being use in bet-history pages, and bet-history store
export const FRONT_END_BET_STATUS = {
    cancelByAdmin: 'CANCEL_BY_ADMIN',
    cancelByProvider: 'CANCEL_BY_PROVIDER',
}

export const FRONTEND_ERROR_CODES = {
    imageSizeShouldBeLessThan: 100000,
    acceptOnlyIcoFile: 100001,
    pleaseSelectOnlyPngIco: 100002,
    pleaseSelectImageFileValidationError: 100003,
    imageSizeShouldBeLess: 100004,
    acceptOnlyJpegFile: 100005,
    pleaseEnterAgentId: 100006,
    thisPhoneNumberIsNotFound: 100007,
    pleaseSelectACurrency: 100008,
    pleaseEnterTheTitle: 100009,
    fieldsAreRequired: 100010,
    pleaseLogoutAndLoginAgain: 100011,
    invalidWalletAddress: 100012,
    timeIsOverlapping: 100013,
    pleaseSelectGameType: 100015,
    pleaseTryAgain: 100016,
    youDonTHavePermission: 100017,
    pleaseEnterCompleteAllTheForms: 100019,
    isRequired: 100020,
    rolloverIsRequired: 100021,
    minimumIs1: 100022,
    decimalIsOnlyOne: 100023,
    countryCodeIsRequired: 100024,
    transactionIdIsRequired: 100025,
    bankReferenceNumberIsRequired: 100026,
    authTypeIsRequired: 100027,
    titleIsRequired: 100028,
    providerNameIsRequired: 100029,
    currencyIsRequired: 100030,
    merchantIdIsRequired: 100031,
    publicKeyIsRequired: 100032,
    secretKeyIsRequired: 100033,
    tokenIsRequired: 100034,
    remarksIsRequired: 100035,
    paymentChannelIsRequired: 100036,
    companyBankAccountIsRequired: 100037,
    playerIdIsRequired: 100038,
    receivingBankAccountIsRequired: 100039,
    amountIsRequired: 100040,
    maxDepositAmountShouldBe: 100042,
    decimalIsTwoDigit: 100043,
    playerBankAccountIsRequired: 100044,
    receivingWalletAddressIsRequired: 100045,
    minDepositAmountShouldBe: 100046,
    exchangeRateIsRequired: 100047,
    decimalIsElevenDigit: 100048,
    playerSWalletInformationIsRequired: 100049,
    transactionIdShouldBeNumberAndAlphabetOnly: 100051,
    maxLength: 100052,
    inValidUrl: 100053,
    theAmountLengthShouldBeLessThan: 100054,
    pleaseEnterVideoContentTitle: 100055,
    titleMaxLength40: 100056,
    amountShouldNotContainCharacter: 100057,
    amountMustGreaterThan0: 100058,
    typeIsNotSupported: 100059,
    typeIsRequired: 100060,
    bannerWidthMustBeValid: 100061,
    bannerHeightMustBeValid: 100062,
    accountHolderNameIsRequired: 100063,
    accountHolderNameShouldBeAlphabetsAndDotsOnly: 100064,
    accountNumberIsRequired: 100065,
    accountNumberMustBeValid: 100066,
    bankNameIsRequired: 100067,
    phoneNumberIsRequired: 100068,
    required: 100069,
    walletAddressIsRequired: 100070,
    invalidAddressFormat: 100071,
    networkIsRequired: 100072,
    cryptoWalletAddressIsRequired: 100073,
    inValidWalletAddress: 100074,
    nameIsRequired: 100075,
    nameShouldBeAlphabetsAndDotsOnly: 100076,
    conditionTypeIsRequired: 100077,
    conditionsIsRequired: 100078,
    variableIsRequired: 100079,
    valueTypeIsRequired: 100080,
    minValueShouldBe: 100081,
    maxValueShouldBe: 100082,
    dayIsRequired: 100083,
    startTimeIsRequired: 100084,
    endTimeIsRequired: 100085,
    timeShouldBeValid: 100086,
    balanceTypeIsRequired: 100087,
    methodIsRequired: 100088,
    maximumBonusIsRequired: 100089,
    withdrawalTypeIsRequired: 100090,
    withdrawalValueIsRequired: 100091,
    pleaseAddNumber: 100092,
    minDepositIsRequired: 100093,
    maxDepositIsRequired: 100094,
    minWithdrawalIsRequired: 100095,
    maxWithdrawalIsRequired: 100096,
    depositVerificationIsRequired: 100097,
    ipIsRequired: 100098,
    invalidIp: 100099,
    usernameIsRequired: 100100,
    passwordIsRequired: 100101,
    passwordGreaterThan6: 100102,
    passwordNotMatch: 100103,
    onlyCharactersAndNumbers: 100104,
    minLengthShouldBe_5: 100105,
    maxLengthShouldBe_10: 100106,
    maximumIs100: 100107,
    realNameIsRequired: 100108,
    realNameShouldBeAlphabetsAndDotsOnly: 100109,
    bankAccountNumberIsRequired: 100110,
    agentDomainIsRequired: 100111,
    URLIsRequired: 100112,
    rateIsRequired: 100204,
    decimalPointIs6Digit: 100114,
    accountNameIsRequired: 100115,
    thresholdLimitIsRequired: 100116,
    agentIsRequired: 100117,
    cryptoAddressIsRequired: 100118,
    '3rdPartyBrandIsRequired': 100120,
    thirdPartyChannelIsRequired: 100121,
    sourceNameIsRequired: 100122,
    redirectUrlIsRequired: 100123,
    categoryIsRequired: 100124,
    costIsRequired: 100125,
    brandNameIsRequired: 100126,
    '2ndTierNameIsRequired': 100127,
    subWebsiteNameIsRequired: 100128,
    landingPageUrlIsRequired: 100129,
    quotaIsRequired: 100130,
    allocatedQuotaExceedsAvailableQuota: 100131,
    revenueShareIsRequired: 100132,
    revenueShareMustBeBetween0and100: 100133,
    '2ndTierShareMustBeBetween0And100': 100134,
    '1stTierApiMarkupMustBeBetween0and100': 100135,
    adjustmentTypeIsRequired: 100136,
    claimTypeIsRequired: 100137,
    claimCycleIsRequired: 100138,
    timezoneIsRequired: 100139,
    referralDateIsRequired: 100140,
    dateMustGreaterThan0: 100141,
    dateMustBe28OrLess: 100142,
    claimPeriodIsRequired: 100143,
    dateMustBeGreaterThan0: 100144,
    dateMustBe31OrLess: 100145,
    percentageIsRequired: 100146,
    mustBeZeroOrGreater: 100147,
    mustBeHundredsOrLess: 100148,
    maxRewardIsRequired: 100149,
    mustBeGreaterThanZero: 100150,
    promotionNameIsRequired: 100151,
    timeZoneIsRequired: 100152,
    startDateIsRequired: 100153,
    endDateIsRequired: 100154,
    playerTypeIsRequired: 100155,
    displayNameIsRequired: 100156,
    rewardIsRequired: 100157,
    codeTypeIsRequired: 100158,
    minimumCodeIs: 100159,
    maximumCodeIs: 100160,
    turnoverIsRequired: 100161,
    balanceIsRequired: 100162,
    allowedToWithdrawalIsRequired: 100163,
    claimCountIsRequired: 100164,
    rebateDateIsRequired: 100165,
    customDomainIsRequired: 100166,
    inValidDomainFormat: 100167,
    minimum: 100168,
    maximum: 100169,
    descriptionIsRequired: 100170,
    phoneIsRequired: 100171,
    usernameMaxLengthIs10: 100172,
    adminTypeIsRequired: 100173,
    roleIsRequired: 100174,
    passwordGreaterThan: 100175,
    onlyAllowAlphabetNumeric: 100176,
    playerIdStartWithLowerCaseAlphabet: 100177,
    validatePlayerId: 100178,
    merchantIdMustBeUpdated: 100179,
    publicKeyMustBeUpdated: 100180,
    secretKeyMustBeUpdated: 100181,
    tokenMustBeUpdated: 100182,
    startMustBeLessThanEndRange: 100183,
    startRangeMustBeGreaterThan0: 100184,
    overlappingRange: 100185,
    endRangeMustBeGreaterThanStart: 100186,
    endRangeMustBeGreaterThan0: 100187,
    duplicatedIp: 100188,
    minDepositGreaterThanZero: 100189,
    minDepositShouldLessThan: 100190,
    minDepositShouldLessThanMaxDeposit: 100191,
    minWithdrawalGreaterThanZero: 100192,
    minWithdrawalShouldLessThan: 100193,
    minWithdrawalShouldLessThanMaxWithdrawal: 100194,
    onlyNumbersAreAllowed: 100195,
    invalid: 100205,
    walletReferenceNumberIsRequired: 100206,
    '1stTierShareMustBeBetween0and100': 100207,
    valueIsRequired: 100208,
    cmsArticleDetailMustGreaterThan20: 100196,
    pleaseAddATitle: 100197,
    requiredSubjectAndText: 100198,
    checkSubjectOrMessage: 100199,
    contactIt: 100200,
    saveError: 100201,
    statusUpdateFailed: 100202,
    claimIntervalIsRequired: 100209,
    endDateRequired: 100154,
    startDateRequired: 100153,
    depositAmountIsRequired: 100210,
    expectedImageSizeIs: 100203,
    endDateMustBeGreaterThanStartDate: 100211,
}
